import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import isString from 'lodash-es/isString';
import { BehaviorSubject, combineLatest as observableCombineLatest, of as observableOf, Subscription } from 'rxjs';

import { ValidationMessageService } from './validation-message.service';

export type RuleOption = 'dirty' | 'touched';

@Component({
  selector: 'control-messages',
  template: `
    <div class="form__validation-message form-control-feedback" *ngIf="showError && errorMessage">
      {{ errorMessage }}
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
})
export class ControlMessages implements OnInit, OnDestroy, OnChanges {
  @Input()
  public control: FormControl;
  @Input()
  public when: RuleOption[] = ['dirty'];
  @Input()
  public parentCheck: string[];

  public errorMessage: string = null;
  public set showError(value: boolean) {
    this.showError$.next(value);
    this._showError = value;
  }
  public get showError(): boolean {
    return this._showError;
  }
  public _showError: boolean;
  public showError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscription = new Subscription();

  public constructor(
    private validationMessageService: ValidationMessageService,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (!this.control) {
      return;
    }
    this.subscription.add(
      observableCombineLatest([this.control.valueChanges, this.control.statusChanges, this.checkParent()]).subscribe(
        () => {
          this.checkMessage();
        },
        (error) => console.error(error),
      ),
    );
  }

  public ngOnChanges() {
    this.checkMessage();
  }

  public checkMessage() {
    this.errorMessage = this.getErrorMessage();

    if (!this.hasError() || (this.errorMessage && !isString(this.errorMessage))) {
      this.showError = false;
      this.cd.markForCheck();
      return;
    }

    if (this.hasServerSideErrors()) {
      this.showError = true;
    } else {
      this.showError = this.when.every((rule) => !!this.control[rule]);
    }
    this.cd.markForCheck();
  }

  private checkParent() {
    if (!this.parentCheck || this.parentCheck.length === 0) {
      return observableOf('');
    }

    return this.control.parent.statusChanges;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private get controlErrorKeys(): string[] {
    return Object.keys(this.control?.errors || {});
  }

  public hasError() {
    if (this.controlErrorKeys.length > 0 || this.getErrorMessageFromParent() !== null) {
      return true;
    }
    return false;
  }

  public getErrorMessage() {
    const firstErrorKey = this.controlErrorKeys[0];
    if (firstErrorKey) {
      return this.validationMessageService.getErrorMessage(firstErrorKey, this.control?.errors[firstErrorKey]);
    }

    return this.getErrorMessageFromParent();
  }

  public hasServerSideErrors() {
    return this.control && this.control.errors ? this.control.errors && this.control.errors['serverSide'] : null;
  }

  public getErrorMessageFromParent(): string {
    if (!this.parentCheck) {
      return null;
    }

    const parentControl = this.control.parent;

    const errors = parentControl?.errors;

    if (!errors) {
      return null;
    }

    for (const parentCheckItem of this.parentCheck) {
      const propertyName = parentCheckItem;
      if (errors[propertyName]) {
        return this.validationMessageService.getErrorMessage(propertyName, errors[propertyName]);
      }
    }

    return null;
  }
}
